@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.hero h1 {
    margin: 0;
    font-size: 65px;
    font-family: 'Roboto';
    font-weight: 700;
}

.hero h2 {
    margin: 0;
    margin-top: 10px;
    font-size: 19px;
    font-family: 'Roboto';
    font-weight: 500;
    color: gray;
}

.hero-social {
    margin-top: 10px;
}

.hero span {
    padding: 5px;
    font-size: 50px;
}

.hero img {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    background-color: black;
    border-radius: 20px;
    border: 10px solid #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.social-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.social-container img {
    height: 50px;
    width: 50px;
    border: none;
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    margin-right: 10px;
}
