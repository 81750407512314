@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
body, html {
  margin: 0;
  padding: 0;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.hero h1 {
    margin: 0;
    font-size: 65px;
    font-family: 'Roboto';
    font-weight: 700;
}

.hero h2 {
    margin: 0;
    margin-top: 10px;
    font-size: 19px;
    font-family: 'Roboto';
    font-weight: 500;
    color: gray;
}

.hero-social {
    margin-top: 10px;
}

.hero span {
    padding: 5px;
    font-size: 50px;
}

.hero img {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
    background-color: black;
    border-radius: 20px;
    border: 10px solid #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.social-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.social-container img {
    height: 50px;
    width: 50px;
    border: none;
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    margin-right: 10px;
}

.experiences-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.heading {
    display: flex;
    width: 520px;
}

.heading-title {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    justify-content: center;
    margin-left: 25px;
}

.heading img {
    height: 80px;
    width: 80px;
    border-radius: 20px;
    border: 7px solid #fff;    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(310deg, #d8d8d8, #ceeaeb, #a2d1d8, #8ce1f8);
    background-size: 800% 800%;

    -webkit-animation: AnimationName 30s ease infinite;
    animation: AnimationName 30s ease infinite;
    }

    @-webkit-keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    @keyframes AnimationName {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
}

.heading-title h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #656B79;
}

.heading-title h1 {
    margin: 0;
    font-size: 35px;
}

.body {
    line-height: 30px;
    width: 520px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-bottom: 1px solid black;
    padding-bottom: 30px;
}

.body p {
    color: #455464;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
}

.body span {
    font-weight: 500;
    color: #455464;
}

.list-body {
    line-height: 30px;
    width: 520px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
}

.list-body p {
    padding-left: 95px;
    color: #455464;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
}

.list-body span {
    font-weight: 500;
    color: #455464;
}

.list-body-last {
    line-height: 30px;
    width: 520px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
    padding-bottom: 30px;
    border-bottom: 1px solid black;
}

.list-body-last p {
    padding-left: 95px;
    color: #455464;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
}

.list-body-last span {
    font-weight: 500;
    color: #455464;
}

.list-heading {
    margin-top: 40px;
    display: flex;
    width: 520px;
}

.list-heading h1 {
    margin: 0;
    font-size: 22px;
}

.list-heading h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #656B79;
}

.list-heading h3 {
    margin: 0;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 500;
    color: #656B79;
}

.list-heading img {
    height: 60px;
    width: 60px;
    border-radius: 20px;
    border: 5px solid #fff;  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}

.stack-container {
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-top: 13px;
}

.stack-container img {
    height: 40px;
    width: 40px;
    border: none;
    background-color: none;
    border-radius: 0;
    box-shadow: none;
    margin-right: 10px;
}

.links-container {
    display: flex;
    align-items: center;
    background-color: none;
}

.links-container a {
    margin-left: 95px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    color: #3183D5;
    text-decoration: none;
}

.links-container a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.chevron {
    color: #3183D5;
    margin-top: 4px;
    font-size: 20px;
}

.chevron:hover {
    cursor: pointer;
}

.link {
    display: flex;
}

@media screen and (max-width: 425px) {
    .heading img {
        margin-left: 30px;
    }
    .list-heading img {
        margin-left: 30px;
    }
    .list-heading h1 {
        width: 180px;
    }
    .stack-container img {
        margin-left: 0;
    }
    .list-body p {
        padding-left: 125px;
        padding-right: 40px;
    }
    .list-body-last p {
        padding-left: 125px;
        padding-right: 40px;
    }
    .links-container a {
        margin-left: 125px;
    }
    .body p {
        padding-left: 30px;
        padding-right: 30px;
    }
}
